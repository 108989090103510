



























































































import { Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import DefaultIntegration from "./DefaultIntegration.vue";
import jsonAPI from "@/api";

type Integration = {
  id?: string;
  provider: string;
  type: string;
  data: any;
};

@Component({
  name: "ChatGptIntegration",
  computed: {
    ...mapGetters({
      organization: "getOrganization",
    }),
  },
  components: {
    ButtonLoading,
  },
})
export default class extends DefaultIntegration {
  name = "chatGpt settings";
  provider = "chatGpt";
  type = "AI";
  data = {
    style: "CONVERSATIONAL",
    tone: "We",
    prompt: "",
  };

  mounted() {
    jsonAPI.get(`${this.route}/type/${this.type}`).then((response) => {
      const data: Integration = response.data as Integration;
      this.id = data.id ?? "";
      this.data = data.data ?? {
        style: "CONVERSATIONAL",
        tone: "We",
        prompt: "",
      };
    });
  }
}
