

































































import { Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import DefaultIntegration from "./DefaultIntegration.vue";

@Component({
  name: "TwilioIntegration",
  computed: {
    ...mapGetters({
      organization: "getOrganization",
    }),
  },
  components: {
    ButtonLoading,
  },
})
export default class extends DefaultIntegration {
  name = "Mail settings";
  provider = "mail";
  type = "EMAIL";
  data = {
    from_mail: "",
    from_name: "",
  };

}
