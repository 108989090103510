import { render, staticRenderFns } from "./SmtpIntegration.vue?vue&type=template&id=2ee47bba&scoped=true&"
import script from "./SmtpIntegration.vue?vue&type=script&lang=ts&"
export * from "./SmtpIntegration.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ee47bba",
  null
  
)

export default component.exports