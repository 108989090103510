

























































































import { Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import DefaultIntegration from "./DefaultIntegration.vue";

@Component({
  name: "VonageIntegration",
  computed: {
    ...mapGetters({
      organization: "getOrganization",
    }),
  },
  components: {
    ButtonLoading,
  },
})
export default class extends DefaultIntegration {
  name = "Vonage settings";
  provider = "vonage";
  type = "SMS";
  data = {
    apiKey: "",
    sid: "",
    telephone: "",
  };
}
