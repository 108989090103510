




import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import { updateApi } from "@/helpers/apiConnection";
import jsonAPI from "@/api";

type Integration = {
  id?: string;
  provider: string;
  type: string;
  data: any;
};

@Component({
  name: "DefaultIntegration",
  computed: {
    ...mapGetters({
      organization: "getOrganization",
    }),
  },
  components: {
    ButtonLoading,
  },
})
export default class extends Vue {
  loading = false;
  id = "";
  type = "";
  provider = "";
  name = "";
  data = {}
  route = "integration"

  mounted() {
    jsonAPI.get(`${this.route}/type/${this.type}`).then((response) => {
      const data: Integration = response.data as Integration;
      this.id = data.id ?? "";
      this.data = data.data ?? {};
    });
  }

  async submitForm() {
    if (this.id) {
      this.updateIntegration();
    } else {
      this.createIntegration();
    }
  }

  async createIntegration() {
    const data = {
      organization_id: (this as any).organization.id,
      provider: this.provider,
      type: this.type,
      data: this.data,
    };
    this.loading = true;
    try {
      await jsonAPI.post(this.route, data);
      window.Bus.$emit("flash-message", {
        text: `${this.name} saved`,
        type: "succes",
      });
      this.$router.push("/organization/integrations");
    } catch (error) {
      window.Bus.$emit("flash-message", {
          text: "An error occurred while saving the settings.",
          type: "error",
        });
    }
    this.loading = false;
  }

  async updateIntegration() {
    const data = {
      id: this.id,
      organization_id: (this as any).organization.id,
      provider: this.provider,
      type: this.type,
      data: this.data,
    };
    this.loading = true;
    try {
      await updateApi(`${this.route}/${this.id}`, data);
      window.Bus.$emit("flash-message", {
        text: `${this.name} saved`,
        type: "succes",
      });
      this.$router.push("/organization/integrations");
    } catch (error) {
      window.Bus.$emit("flash-message", {
          text: "An error occurred while saving the settings.",
          type: "error",
        });
    }
    this.loading = false;
  }

  async deleteSettings() {
    this.loading = true;
    try {
      await jsonAPI.delete(`${this.route}/${this.id}`);
      window.Bus.$emit("flash-message", {
        text: `${this.name} deleted`,
        type: "succes",
      });
      this.$router.push("/organization/integrations");
    } catch (error) {
      window.Bus.$emit("flash-message", {
          text: "An error occurred while saving the settings.",
          type: "error",
        });
    }
    this.loading = false;
  }
}
