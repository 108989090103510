export default [
  {
    id: "vonage",
    name: "Vonage",
    description:
      "Enter your Vonage API key and secret here to enable two way communication with your customers.",
    component: "VonageIntegration",
    logo: "VonageLogo.png",
    tags: ["communication", "sms"],
  },
  {
    id: "twilio",
    name: "Twilio",
    description:
      "Enter your Twilio API key and sid here to enable two way communication with your customers.",
    component: "TwilioIntegration",
    logo: "TwilioLogo.png",
    tags: ["communication", "sms"],
  },
  {
    id: "woocommerce",
    name: "Woocommerce",
    description:
      "Connect to your woocommerce store to enable access to your products and update synchronisation.",
    component: "WoocommerceIntegration",
    logo: "WoocommerceLogo.png",
    tags: ["ecommerce"],
  },
  {
    id: "chatGpt",
    name: "ChatGpt",
    description: "Configure ChatGpt for your automations.",
    component: "ChatGptIntegration",
    logo: "open-ai-logo.png",
    tags: ["communication", "email", "AI"],
  },
  {
    id: "mail",
    name: "Mail",
    description: "Connect to your mail server to enable email communication.",
    component: "MailIntegration",
    logo: "MailLogo.png",
    tags: ["communication", "email"],
  },
  {
    id: "smtp",
    name: "SMTP",
    description: "Send emails via smtp.",
    component: "SmtpIntegration",
    logo: "SmtpLogo.png",
    tags: ["communication", "email"],
  },
  {
    id: "mailgun",
    name: "MailGun",
    description: "Send emails with MailGun.",
    component: "MailgunIntegration",
    logo: "MailGunLogo.png",
    tags: ["communication", "email"],
  },
  {
    id: "ses",
    name: "SES",
    description: "Send emails with SES.",
    component: "SesIntegration",
    logo: "SesLogo.png",
    tags: ["communication", "email"],
  }
];
