
























































































































































import { Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import DefaultIntegration from "./DefaultIntegration.vue";
import Multiselect from "vue-multiselect";

@Component({
  name: "TwilioIntegration",
  computed: {
    ...mapGetters({
      organization: "getOrganization",
    }),
  },
  components: {
    ButtonLoading,
    Multiselect,
  },
})
export default class extends DefaultIntegration {
  name = "SMTP settings";
  provider = "smtp";
  type = "EMAIL";
  data = {
    from_mail: "",
    from_name: "",
    mail_host: "",
    mail_port: "",
    mail_username: "",
    mail_password: "",
    mail_encryption: "",
  };
  encryptions = ["tls", "ssl", "starttls"];

}
