import { render, staticRenderFns } from "./WoocommerceIntegration.vue?vue&type=template&id=a567bb46&scoped=true&"
import script from "./WoocommerceIntegration.vue?vue&type=script&lang=ts&"
export * from "./WoocommerceIntegration.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a567bb46",
  null
  
)

export default component.exports