
































































import { Organization } from "@/types/organization";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ConnectButton from "@/components/Account/ConnectButton.vue";
import Tooltip from "@/components/UI/Tooltip.vue";

type WooCommerceSettings = {
  key_id: string;
  provider: string;
  consumer_key: string;
  consumer_secret: string;
  key_permissions: string;
};

@Component({
  name: "WoocommerceIntegration",
  computed: {
    ...mapGetters({
      organization: "getOrganization",
    }),
  },
  components: {
    ConnectButton,
    Tooltip,
  },
})
export default class extends Vue {
  get settings(): WooCommerceSettings | null {
    const organization: Organization = (this as any).organization;
    if (organization && organization.settings) {
      const integrations = organization.settings.integration;
      if (integrations) {
        const WooCommerceSettings = integrations.find(
          (integration: any) => integration.provider === "woocommerce"
        );
        return WooCommerceSettings ?? null;
      }
    }
    return null;
  }

  get consumerKey(): string {
    const settings = this.settings;
    if (settings) {
      return settings.consumer_key;
    }
    return "";
  }
  get consumerSecret(): string {
    const settings = this.settings;
    if (settings) {
      return settings.consumer_secret;
    }
    return "";
  }
  get org(): Organization {
    return (this as any).organization;
  }
}
