













import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Default from "@/layouts/default.vue";
import VonageIntegration from "@/components/integrations/VonageIntegration.vue";
import TwilioIntegration from "@/components/integrations/TwilioIntegration.vue";
import WoocommerceIntegration from "@/components/integrations/WoocommerceIntegration.vue";
import ChatGptIntegration from "@/components/integrations/ChatGptIntegration.vue";
import MailIntegration from "@/components/integrations/MailIntegration.vue";
import SesIntegration from "@/components/integrations/SesIntegration.vue";
import SmtpIntegration from "@/components/integrations/SmtpIntegration.vue";
import MailgunIntegration from "@/components/integrations/MailgunIntegration.vue";
import integrations from "@/helpers/integrations";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";

@Component({
  name: "IntegrationSetup",
  components: {
    Default,
    ConfigurationNav,
    VonageIntegration,
    TwilioIntegration,
    WoocommerceIntegration,
    ChatGptIntegration,
    MailIntegration,
    MailgunIntegration,
    SmtpIntegration,
    SesIntegration,
  },
  computed: {
    ...mapGetters({
      organization: "getOrganization",
    }),
  },
})
export default class extends Vue {
  layout = "Default";
  title = "";
  integrationComponent = "";

  mounted() {
    const key = this.$route.params.key;
    this.title = key;
    const currentIntegration = integrations.find(
      (integration) => integration.id === key
    );
    if (currentIntegration) {
      this.integrationComponent = currentIntegration.component;
    }
  }
}
